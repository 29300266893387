
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Multiselect from "@vueform/multiselect";
import { onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  getDevicesData,
  getBinList,
  getCustomersList,
} from "@/store/api/devices";

export default defineComponent({
  components: {
    Multiselect,
    Datatable,
  },
  name: "kt-invoices",
  props: {
    cardClasses: String,
  },
  methods: {
    slotName(name) {
      return "cell-" + name;
    },
  },
  setup() {
    const loading = ref<boolean>(false);
    const companiesLoading = ref<boolean>(false);
    const devicesLoading = ref<boolean>(false);
    const allDevicesCheckbox = ref<boolean>(false);
    const qualify = ref<boolean>(false);
    let dataAvailable = ref<boolean>(false);
    const deviceTableData = ref<Array<any>>([]);
    const allData = ref<Array<any>>([]);
    const devicesName = ref<Record<string, any>>({});
    const keys = ref<Set<any>>(new Set());
    const companyList = ref({ value: 0, options: new Array({}) });
    const deviceList = ref({
      value: new Array({}),
      options: new Array({ label: "", value: "" }),
    });
    const date = ref([]);
    const months = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const errortype = ref({
      value: 0,
      options: ["All", "Online", "Offline", "511"],
    });
    const tableHeader = ref([
      {
        name: "Devices",
        key: "deviceId",
        sortable: false,
      },
    ]);

    onMounted(async () => {
      companiesLoading.value = true;
      let companies = await getCustomersList();
      for (let company of companies) {
        companyList.value.options.push({
          label: company.name,
          value: company.id,
        });
      }
      companiesLoading.value = false;
      // loadData()
      setCurrentPageBreadcrumbs("Devices Report", [""]);
    });
    const onCompanyChange = async (companyId) => {
      allDevicesCheckbox.value = false;
      devicesLoading.value = true;
      deviceList.value.options.splice(0, deviceList.value.options.length);
      deviceList.value.value.splice(0, deviceList.value.value.length);
      let payload = {
        customerId: companyId,
      };
      let devices = await getBinList(payload);
      for (let key of Object.keys(devicesName.value)) {
        delete devicesName.value[key];
      }
      for (let device of devices.devices) {
        deviceList.value.options.push({
          label: device.name,
          value: device.variant_id.toUpperCase(),
        });
        devicesName.value[device.variant_id.toUpperCase()] = device.name;
      }
      devicesLoading.value = false;
    };
    const selectAllDevices = (event) => {
      if (event.target.checked) {
        deviceList.value.value = [];
        if (companyList.value.value) {
          for (let i = 0; i < deviceList.value.options.length; i++) {
            if (Object.keys(deviceList.value.options[i]).length > 0) {
              deviceList.value.value.push(deviceList.value.options[i].value);
            }
          }
        }
      }
    };
    const changeDataByType = (errorType) => {
      let deviceData = JSON.parse(JSON.stringify(allData.value));
      let newData: any = [];
      if (errorType == "All") {
        deviceTableData.value.splice(0, deviceTableData.value.length);
        for (let data of allData.value) {
          deviceTableData.value.push(data);
        }
        return;
      }
      for (let data of deviceData) {
        qualify.value =
          errorType == "Offline" || errorType == "511" ? false : true;

        for (let key of Object.keys(data)) {
          if (key != "deviceId") {
            if (errorType == "Online") {
              if (data[key] == "offline") {
                qualify.value = false;
              } else if (typeof data[key] == "number") {
                if (data[key] > 0) {
                  qualify.value = false;
                }
              }
            } else if (errorType == "Offline") {
              if (data[key] == "offline") {
                qualify.value = true;
              }
            } else if (errorType == "511") {
              if (typeof data[key] == "number") {
                if (data[key] > 0) {
                  qualify.value = true;
                }
              }
            }
          }
        }
        if (qualify.value == true) {
          newData.push(data);
        }
      }

      deviceTableData.value.splice(0, deviceTableData.value.length);
      for (let filteredData of newData) {
        deviceTableData.value.push(filteredData);
      }
    };
    const fetchDeviceData = async () => {
      loading.value = true;
      tableHeader.value = tableHeader.value.slice(0, 1);
      deviceTableData.value.splice(0, deviceTableData.value.length);
      let devices: Record<string, any> = [];
      deviceList.value.value.forEach((device) => {
        devices.push(device);
      });

      let start = new Date(date.value[0]);
      let end = new Date(date.value[1]);
      let startYear = start.getFullYear();
      let endYear = end.getFullYear();
      let startMonth = start.getMonth() + 1;
      let endMonth = end.getMonth() + 1;
      let startDate = start.getDate();
      let endDate = end.getDate();
      let payload = {
        start: `${startYear}-${startMonth}-${startDate}`,
        end: `${endYear}-${endMonth}-${endDate}`,
        deviceIds: devices,
      };

      let data: Record<string, unknown> = {};
      let dates: string[] = [];
      try {
        const devicesData = await getDevicesData(payload);

        keys.value?.add("deviceId");
        for (let deviceId in devicesData) {
          data["deviceId"] =
            devicesName.value[deviceId.toUpperCase()] ??
            devicesName.value[deviceId.toLowerCase()];
          for (let year in devicesData[deviceId]) {
            for (let month in devicesData[deviceId][year]) {
              for (let date in devicesData[deviceId][year][month]) {
                {
                  if (
                    dates.find((val) => val === `${year}-${month}-${date}`) ===
                    undefined
                  ) {
                    tableHeader.value.push({
                      name: `${months[month]}-${date}`,
                      key: `value-${months[month] + date}`,
                      sortable: false,
                    });
                    dates.push(`${year}-${month}-${date}`);
                  }
                  data[`value-${months[month] + date}`] =
                    devicesData[deviceId][year][month][date];
                  keys.value?.add(`value-${months[month] + date}`);
                }
              }
            }
          }
          deviceTableData.value.push(data);
          data = {};
        }
        for (const data of deviceTableData.value) {
          allData.value.push(data);
        }
        changeDataByType(errortype.value.value);
      } catch (error) {
        console.log(error);
      }
      dataAvailable.value = true;
      loading.value = false;
    };

    return {
      tableHeader,
      deviceTableData,
      errortype,
      companyList,
      loading,
      companiesLoading,
      devicesLoading,
      deviceList,
      date,
      onCompanyChange,
      fetchDeviceData,
      allDevicesCheckbox,
      dataAvailable,
      keys,
      selectAllDevices,
      changeDataByType,
    };
  },
});
